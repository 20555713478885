import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Papa from 'papaparse';
import {linearGradientDef} from '@nivo/core'
import {ResponsiveLine} from '@nivo/line';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Upload, DatePicker, Statistic, Card, Empty, Typography, Collapse, Space, Steps } from 'antd';

const { Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

const steps = [
    {
        title: 'Login',
        content: 'Login to your bank, select the account your want a timeline for.',
    },
    {
        title: 'Export',
        content: 'Use your bank\'s transactions export feature to export as a .csv. Make sure that your exported date range begins at the account open, it is important that account balance starts at zero for this tool to be accurate. Make sure the export format is CSV dd/mm/yyy.',
    },
    {
        title: 'Import',
        content: 'Click the import button here to load the .csv and create your timeline. You can investigate different date ranges with the date-picker too.',
    },
];

function App() {
    const [data, setData] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    // TODO: Also set min max Dollar for x axis
    // const [dollarRange, setDollarRange] = useState(null);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);

    const next = () => {
        setStep(step + 1);
    };

    const prev = () => {
        setStep(step - 1);
    };

    const changeHandler = (info) => {
        setLoading(true);
        // https://ant.design/components/upload/#Why-does-onChange-sometimes-return-File-object-and-other-times-return-{-originFileObj:-File-}
        Papa.parse(info.file.originFileObj, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setData(toGraphData(results.data));
                setLoading(false);
            },
        });
    };

    const graphData = [
        {
            "id": "Balance",
            "color": "hsl(96, 70%, 50%)",
            "data": data
        }
    ];

    const onDateRangeChange = (date) => {
        // Clear
        if (!date) {
            setDateRange(null);
            // setDollarRange(null);
            return;
        }

        const start = date[0]._d;
        const end = date[1]._d;

        setDateRange([dateToString(start), dateToString(end)]);
    };

    return (
        <div
            className="App"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                minWidth: '100vw',
            }}
        >
            <Space direction="vertical" size="middle">
                <Title>numbi</Title>


            { loading &&
                <LoadingOutlined style={{fontSize: 100}} />
            }
            { !loading &&
                <>
                    { data &&
                    <Card>
                        <div style={{height: '90vh', width: '95vw'}}>
                            <MyResponsiveLine
                                data={graphData}
                                dateRange={dateRange}
                                // dollarRange={dollarRange}
                            />
                        </div>
                        <RangePicker
                            onChange={onDateRangeChange}
                            format={'DD/MM/YYYY'}
                        />
                    </Card>
                    }
                    { !data &&
                        <>
                        <Collapse>
                            <Panel header="What... is this?" key="1">
                                A simple tool to view your bank account balance in a line graph; balance over time.<br/>
                                Use it to think about your life journey in numbers.
                            </Panel>
                            <Panel header="How do I use it?" key="2">
                                <Space direction="vertical" size="small">
                                    <Steps current={step}>
                                        {steps.map(item => (
                                            <Step key={item.title} title={item.title} />
                                        ))}
                                    </Steps>
                                    <Card>{steps[step].content}</Card>
                                    <div className="steps-action">
                                        {step > 0 && (
                                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                Previous
                                            </Button>
                                        )}
                                        {step < steps.length - 1 && (
                                            <Button type="primary" onClick={() => next()}>
                                                Next
                                            </Button>
                                        )}
                                    </div>
                                </Space>
                            </Panel>
                            <Panel header="Am I safe?" key="3">
                                This tool doesn't send anything to the internet, none of your details, even those that you import will be sent anywhere.<br/>
                                When you import your exported transactions .csv it's temporarily stored locally on your device and calculated there too.<br/>
                                Your transactions and account balance could be considered sensitive data, that's why this is all kept on your device.<br/>
                                Shh...
                            </Panel>
                        </Collapse>
                        <Card>
                            <Empty description={''}/>
                            <Upload
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={changeHandler}
                            >
                                <Button icon={<UploadOutlined />}>Import</Button>
                            </Upload>
                        </Card>
                        </>
                    }
                </>
            }
            </Space>
        </div>
    );
}

const MyResponsiveLine = ({data, dateRange, dollarRange}) => (
    <ResponsiveLine
        data={data}
        margin={{top: 20, right: 20, bottom: 80, left: 60}}
        curve={'linear'}

        enableArea
        colors={['rgb(97, 205, 187)']}
        defs={[
            linearGradientDef('gradientA', [
                { offset: 0, color: 'rgb(97, 205, 187)' },
                { offset: 100, color: 'rgb(97, 205, 187)', opacity: 0 },
            ]),
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}

        enableGridX={false}
        animate={false}
        enableSlices={'x'}
        xScale={{
            type: "time",
            format: "%d/%m/%Y",
            precision: "day",
            min: dateRange ? dateRange[0] : 'auto',
            max: dateRange ? dateRange[1] : 'auto'
        }}
        yScale={{
            type: 'linear',
            min: dollarRange ? dollarRange[0] : 'auto',
            max: dollarRange ? dollarRange[1] : 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickValues: 50,
            tickPadding: 5,
            tickRotation: 90,
            format: "%d/%m/%Y"
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: value => `$${value}`
        }}
        pointSize={1}
        pointColor={{theme: 'background'}}
        pointBorderWidth={1}
        pointBorderColor={{from: 'serieColor'}}
        pointLabelYOffset={-12}
        useMesh={true}
        sliceTooltip={({ slice }) => {
            return (
                <div
                    style={{
                        background: 'white',
                        border: '1px solid rgb(97, 205, 187)',
                    }}
                >
                    {slice.points.map(point => {
                        const date = new Date(point.data.xFormatted);
                        const dateString = dateToString(date);

                        return <Card key={point.id}>
                            <Statistic title={dateString} value={point.data.yFormatted} prefix={'$'} />
                        </Card>
                    })}
                </div>
            )
        }}
    />
);

const { RangePicker } = DatePicker;

function toGraphData(data) {
    let runningTotal = 0;

    const dateBalance = data.map(item => {
        const amount = parseFloat(item.Amount);
        const balance = runningTotal + amount;
        runningTotal = balance;

        return {'x': item.Date, 'y': balance};
    });

    // We want the latest balance for a date, so we reverse the array,
    // this means the entry added to existingDates will be the most recent and we
    // won't add the older ones
    let existingDates = [];
    const dateBalanceDuplicateDatesRemoved = dateBalance.reverse().filter(item => {
        const date = item.x;
        if (!existingDates.includes(date)) {
            existingDates.push(date);
            return true;
        }

        return false;
    }).reverse();

    return dateBalanceDuplicateDatesRemoved;
}

// JS Date Object to dd/mm/yyyy
function dateToString(date) {
    return [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear()
    ].join('/');
}

export default App;
